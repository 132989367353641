<template>
  <div>
    <div style="width: 100%">
      <div class="zcjsq_banner"></div>
    </div>
    <div class="def-container tableCont">
      <img src="~@/assets/images/positionImg.png" alt="" style="" />
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        style="font-size: 18px; display: inline-block; margin-left: 8px"
      >
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: 'policy' }"
          >政策计算器</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: 'policyDetails' }"
          >政策详情</el-breadcrumb-item
        >
      </el-breadcrumb>
      <el-row class="line" style="line-height: 2; margin: auto">
        {{ itemData.name }}
      </el-row>
      <el-divider></el-divider>
      <el-row class="line">
        <el-col :span="2" class="line_label">政策文号:</el-col>
        <el-col :span="6" class="line_value">{{ itemData.fwzh }}</el-col>
        <el-col :span="2" class="line_label">政策级别:</el-col>
        <el-col :span="6" class="line_value">{{ itemData.areaname }}</el-col>
        <el-col :span="2" class="line_label">发布日期:</el-col>
        <el-col :span="6" class="line_value">{{ itemData.publictime }}</el-col>
      </el-row>
      <el-row class="line">
        <!-- <el-col :span="2" class="line_label">政策分类:</el-col>
        <el-col :span="22" class="line_value"
        >{{ itemData.policyClassification }}</el-col
        > -->
        <el-col :span="2" class="line_label" >企业属性:</el-col>
        <el-col :span="6" class="line_value line-limit-length" :title="itemData.enterpriseAttributes">{{
          itemData.enterpriseAttributes || "无"
        }}</el-col>
        <el-col :span="2" class="line_label">企业规模:</el-col>
        <el-col :span="6" class="line_value line-limit-length" :title="itemData.enterpriseSize">{{
          itemData.enterpriseSize || "无"
        }}</el-col>
        <el-col :span="2" class="line_label">行业类别:</el-col>
        <el-col :span="6" class="line_value line-limit-length" :title="itemData.sysCategory">{{
          itemData.sysCategory || "无"
        }}</el-col>
      </el-row>
      <!-- <el-row class="line" style="margin-bottom:20px;">
        <el-col :span="2" class="line_label">行业类别:</el-col>
        <el-col :span="22" class="line_value"
        >{{ itemData.sysCategory }}</el-col
        >
      </el-row> -->
    </div>
    <div class="def-container">
      <div class="radioCon">
        <el-radio-group v-model="radio" @change="radioChange">
          <el-radio-button label="项目列表"></el-radio-button>
          <el-radio-button label="政策详情"></el-radio-button>
        </el-radio-group>
      </div>
      <div
        style="background-color: #fff; margin-bottom: 160px; padding: 15px 50px"
        v-show="indexFlag == '1'"
      >
        <el-table
          :data="tableData"
          style="width: 100%"
          :show-header="false"
          class="applyTable"
          @row-click="tableRowClick"
        >
          <template slot="empty">
            <span>暂无数据</span>
          </template>
          <el-table-column prop="name" label="政策指标" style="height: 2.5rem">
            <template slot-scope="scope">
              <div class="contentCon" style="float: left">
                <div>
                  <el-row>
                    <el-col :span="18">
                      {{ scope.row.name }}
                    </el-col>
                    <el-col :span="6">
                      <span class="subTitle">最高可申报金额：</span
                      >{{ scope.row.zzxe ? scope.row.zzxe : 0 }}（万元）
                    </el-col>
                  </el-row>
                  <!--                  <el-button size="small" type="primary" class="elBtn" v-if="scope.row.money">{{scope.row.money}}</el-button>-->
                  <!-- <el-button size="small" type="primary" class="elBtn" v-if="scope.row.capital">{{scope.row.capital}}</el-button>
                  <el-button size="small" type="primary" class="elBtn" v-else>0</el-button> -->
                </div>
                <div>
                  <span class="subTitle">申报时间：</span
                  ><span
                    >{{ moment(scope.row.startTime).format("YYYY-MM-DD") }}至{{
                      moment(scope.row.endTime).format("YYYY-MM-DD")
                    }}</span
                  >
                  <span class="subTitle" style="margin-left: 20px"
                    >受理部门：</span
                  ><span>{{ scope.row.deptName }}</span>
                </div>
                <div
                  class="fuchi"
                  v-for="(item, index) in scope.row.zzqxList"
                  :key="index"
                >
                  <span style="margin-right: 5px"
                    >资助标准{{
                      scope.row.zzqxList.length > 1 ? index + 1 : ""
                    }}:</span
                  >
                  <span>{{ item.zzqx }}</span>
                </div>
              </div>
              <div class="restDay flex-column flex-center" style="float: right">
                <span v-if="scope.row.timeLimit >= 0">剩余时间</span>
                <span
                  style="color: #ffd188; font-weight: 600"
                  v-if="scope.row.timeLimit >= 0"
                  >{{ scope.row.timeLimit }}天</span
                >
                <span
                  style="color: #ffd188; font-weight: 600"
                  v-if="scope.row.timeLimit < 0"
                  >已过期</span
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        style="background-color: #fff; margin-bottom: 160px; min-height: 300px"
        v-show="indexFlag == '2'"
      >
        <!-- 政策详情待待渲染 -->
        <div class="detail-content" v-html="itemData.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      itemData: null,
      tableData: [],
      radio: "可申报项目",
      indexFlag: "1",
      applyItemData: [],
      moment,
    };
  },

  components: {},

  created() {
    this.projectShow();
  },

  mounted() {
    let data = JSON.parse(sessionStorage.getItem("zcjsqData"));
    this.getNoticeInfo(data.id);
  },

  methods: {
    //根据政策id查询项目
    projectShow() {
      let zcjsqData = JSON.parse(sessionStorage.getItem("zcjsqData"));
      let url = "/dev-api/business/sq-apply/selectProjectList";
      let params = {
        policyId: zcjsqData.id,
        pageSize: 100,
        pageNum: 1,
        serachProject: "",
      };
      let that = this;
      that.$httpApi
        .post(url, params)
        .then((r) => {
          this.tableData = r.list;
        })
        .catch((e) => {});
    },
    tableRowClick(row, column, event) {
      // this.$store.commit("updateCurrentGuideId", row.id);
      // this.$router.push({
      //   path: "/guideSuqiu",
      //   query: {
      //     name: row.name,
      //     itemType: '2',
      //     isAdded: row.isAdded,
      //   },
      // });
    },
    radioChange(val) {
      if (val == "项目列表") {
        this.indexFlag = "1";
      }
      if (val == "政策详情") {
        this.indexFlag = "2";
      }
    },
    getNoticeInfo(id) {
      let that = this;
      if (id) {
        this.$httpApi
          .get(`/dev-api/document/${id}`, {})
          .then((res) => {
            console.log("公告详细信息:", res);
            if (res.code == 200) {
              that.itemData = res.data;
              console.log(that.itemData);
              let data = res.data;
              this.deptObject = {
                deptName: data.deptname,
                deptId: data.deptid,
                fbjg: data.fbjg,
              };
            }
          })
          .catch((err) => {});
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.detail-content {
  line-height: 28px;
  font-size: 14px;
  padding: 15px 50px;
  margin: 20px 0;
  overflow: hidden;
  p {
    text-indent: 2rem;
  }
}
::v-deep .el-radio-button.is-active .el-radio-button__inner {
  border: none;
  background-color: #fff;
  color: #ccc;
}
::v-deep .el-radio-button {
  border: none;
}
::v-deep .el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0;
}
::v-deep .el-radio-button__inner {
  background: #e9e9e9;
  border-color: none;
  color: #000;
  padding: 10px 30px;
}
// ::v-deep .el-radio-button:first-child .el-radio-button__inner {
//   border-left: 1px solid #0069e8;
//   border-radius: 0;
//   box-shadow: none !important;
// }

.line_link {
  font-size: 16px;
  color: #3c67a8;
  text-decoration: underline;
}
.mainCont {
  width: 100%;
  height: 200px;
  background: url("~@/assets/images/党建100周年2.png") no-repeat 50% / cover;
  display: flex;
  align-items: center;
  .def-container {
    .main_title {
      font-size: 18px;
      color: #fff;
      font-weight: 700;
      margin-bottom: 15px;
    }
  }
}
.desc {
  margin-bottom: 15px;
  color: #fff;
  letter-spacing: 2px;
}
.line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 24px;
  color: #333;
  font-weight: 600;
  .line_label {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }
  .line_value {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }
}
.line_link {
  font-size: 16px;
  color: #3c67a8;
  text-decoration: underline;
}
.radioCon {
  width: 100%;
  margin-left: -16px;
  height: 50px;
  border: 1px solid #eeeeee;
  background: linear-gradient(to bottom, #ffffff, #f9f9f9);
  ::v-deep .el-radio-button.is-active .el-radio-button__inner {
    border: none !important;
    // background-color: #22b7fd;
    background-color: #cbe9ff;
    color: #1f59d8;
  }

  ::v-deep .el-radio-button {
    border-color: none;
  }

  ::v-deep .el-radio-button:last-child .el-radio-button__inner {
    border-radius: 0;
  }

  ::v-deep .el-radio-button__inner {
    border: none;
    color: #666666;
    padding: 17px 64px;
  }

  ::v-deep .el-radio-button:first-child .el-radio-button__inner {
    // border-left: 1px solid #22b7fd;
    border-left: transparent;
    border-radius: 0;
    box-shadow: none !important;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    box-shadow: none !important;
  }
}
.applyTable {
  color: #000;
  font-size: 16px;
  .contentCon {
    width: calc(100% - 100px);
    div {
      margin: 10px auto;
    }
  }
  .elBtn {
    margin-left: 40px;
  }
  .fuchi {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .subTitle {
    color: #00a0ff;
    font-weight: 600;
  }
  .restDay {
    width: 90px;
    height: 120px;
    border: 1px solid #ffe8c6;
    line-height: 35px;
  }
}
.tableInfo {
  background-color: #fffaf3;
  color: orange;
  height: 48px;
  line-height: 48px;
}
.tableCont {
  background-color: #fff;
  margin: 16px auto;
  height: 220px;
  position: relative;
}
.rightCont {
  width: 200px;
  height: 100px;
  position: absolute;
  top: 0;
  right: -220px;
  div {
    height: 50px;
    line-height: 50px;
    text-align: center;
  }
  .rightCont_company {
    background-color: $col-theme;
    color: #fff;
  }
  .rightCont_info {
    background-color: #fff;
    color: #333;
  }
}
.zcjsq_banner {
  width: 100%;
  height: 200px;
  background: url("~@/assets/deyangImg/zcjsqBanber.png") no-repeat 100% / cover;
}
::v-deep .el-table th,
.el-table tr {
  background-color: $col-theme;
}
::v-deep .el-table thead {
  color: #fff;
}
</style>
